import * as components from "./components"
export * from "./components"

export default {
  install(app) {
    // map to kebab-case for now until we refactor to CamelCase
    app.component("nice-alert", components.NiceAlert)
    app.component("nice-badge", components.NiceBadge)
    app.component("nice-checkbox", components.NiceCheckbox)

    // // this will install all components by CamelCase
    // Object.keys(components).forEach(key => {
    //   app.component(key, components[key])
    // })
  },
}
